import React, { useState } from 'react';
import { TicketCheck, X, User, Plus } from 'lucide-react';
import { usePlatforms } from '../hooks/usePlatforms';
import Modal from '../components/Modal';

const PlatformsPage: React.FC = () => {
  const { platformData, handleConnect, handleConnectMore, disconnectChannel } =
    usePlatforms();
  const [modalOpen, setModalOpen] = useState(false);
  const [channelToDisconnect, setChannelToDisconnect] = useState<{
    platformIndex: number;
    channelIndex: number;
  } | null>(null);

  const openDisconnectModal = (platformIndex: number, channelIndex: number) => {
    setChannelToDisconnect({ platformIndex, channelIndex });
    setModalOpen(true);
  };

  const handleDisconnect = () => {
    if (channelToDisconnect) {
      disconnectChannel(
        channelToDisconnect.platformIndex,
        channelToDisconnect.channelIndex,
      );
    }
    setModalOpen(false);
    setChannelToDisconnect(null);
  };


  const handleCreateChannel = (platformIndex: number) => {
    const platformName = platformData[platformIndex].name;
    let url = '';
    
    switch (platformName) {
      case 'YouTube':
        url = 'https://www.youtube.com/create_channel';
        break;
      case 'X':
        url = 'https://twitter.com/i/flow/signup';
        break;
      case 'Vimeo':
        url = 'https://vimeo.com/join';
        break;
      default:
        console.error(`Unknown platform: ${platformName}`);
        return;
    }
    
    window.open(url, '_blank');
  }

  const getPlatformColor = (platformName: string) => {
    switch (platformName) {
      case 'YouTube':
        return 'text-red-600';
      case 'X':
        return 'text-sky-500';
      case 'Vimeo':
        return 'text-blue-600';
      default:
        return 'text-cadet-gray';
    }
  };

  return (
    <div className="relative h-full flex flex-col bg-anti-flash-white dark:bg-dark-gray p-4 sm:p-6">
      <div className="flex items-center justify-between mb-6 sm:mb-8">
        <h1 className="text-2xl sm:text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center">
          <TicketCheck className="w-6 h-6 sm:w-8 sm:h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
          Platforms
        </h1>
      </div>

      <div className="flex flex-wrap gap-4 sm:gap-6">
        {platformData.map((platform, platformIndex) => (
          <div
            key={platform.name}
            className="flex flex-col bg-soft-cloud dark:bg-darker-gray border-2 border-cadet-gray dark:border-dark-gray rounded-lg p-4 min-h-[300px] w-full sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] min-w-[340px] max-w-[400px]"
          >
            <div className="flex items-center mb-4">
              <platform.IconComponent
                className={`w-12 h-12 sm:w-16 sm:h-16 ${getPlatformColor(platform.name)}`}
              />
              <h2 className="text-2xl sm:text-3xl font-light text-midnight-coal dark:text-cadet-gray ml-2">
                {platform.name}
              </h2>
            </div>

            {platform.connected ? (
              <div className="flex-grow flex flex-col">
                <p className="text-midnight-coal dark:text-soft-cloud mb-2">
                  Connected Channels:
                </p>
                <div className="flex-grow">
                  {platform.channel.map((ch, channelIndex) => (
                    <div
                      key={channelIndex}
                      className="flex items-center justify-between mb-2 p-2 bg-anti-flash-white dark:bg-dark-gray rounded-lg"
                    >
                      <div className="flex items-center">
                        <div className="w-10 h-10 bg-cadet-gray rounded-full flex items-center justify-center mr-2">
                          <User className="w-6 h-6 text-soft-cloud" />
                        </div>
                        <span className="text-cadet-gray">{ch.name}</span>
                      </div>
                      <button
                        onClick={() =>
                          openDisconnectModal(platformIndex, channelIndex)
                        }
                        className="p-1 bg-soft-cloud dark:bg-dark-gray text-midnight-coal dark:text-soft-cloud rounded-full hover:bg-desert-sand dark:hover:bg-cadet-gray hover:text-soft-cloud transition-colors duration-200"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => handleConnectMore(platformIndex)}
                  className="self-end mt-2 px-4 py-2 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud rounded-lg"
                >
                  Connect More
                </button>
              </div>
            ) : (
              <div className="flex-grow flex flex-col justify-between">
                <p className="text-cadet-gray text-center my-4">
                  Looks like you haven't connected any channels from{' '}
                  {platform.name}.
                </p>
                <div className="flex flex-col sm:flex-row justify-between gap-2">
                  <button
                    onClick={() =>
                      handleCreateChannel(platformIndex)
                    }
                    className="px-4 py-2 bg-soft-cloud dark:bg-dark-gray text-midnight-coal dark:text-soft-cloud rounded-lg flex items-center justify-center hover:bg-anti-flash-white dark:hover:bg-cadet-gray transition-colors duration-200"
                  >
                    <Plus size={16} className="mr-2" />
                    Create channel
                  </button>
                  <button
                    onClick={() => handleConnect(platformIndex)}
                    className="px-4 py-2 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud rounded-lg hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-200"
                  >
                    Connect {platform.name}
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleDisconnect}
        title="Confirm Disconnection"
        message="Are you sure you want to disconnect this channel?"
        confirmText="Disconnect"
      />
    </div>
  );
};

export default PlatformsPage;
