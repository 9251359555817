import { Upload, Image } from 'lucide-react';
import React from 'react';

interface UploadBoxProps {
  className?: string;
  setUploadVideo: (files: File) => void;
}
interface ThumbnailUploadBoxProps {
  className?: string;
  setThumbnail: (file: File) => void;
}

const UploadBox: React.FC<UploadBoxProps> = ({ className, setUploadVideo }) => {
  return (
    <div
      className={`bg-soft-cloud dark:bg-darker-gray p-6 rounded-lg border-2 border-dotted border-midnight-coal dark:border-cadet-gray max-w-xl h-80 ${className}`}
    >
      <div className="flex flex-col items-center justify-center h-full">
        <div className="bg-desert-sand text-darker-gray rounded-full p-8">
          <Upload className="w-12 h-12" />
        </div>
        <p className="text-midnight-coal dark:text-cadet-gray mt-4 text-center text-lg">
          Drag & drop video file(s) to upload.
        </p>
        <input
          type="file"
          className="hidden"
          id="file-upload"
          multiple
          onChange={(e) => {
            const files = e.target.files;
            if (files) {
              setUploadVideo(files[0]);
            }
            const fileInput = e.target;
            const fileName = fileInput.files?.length
              ? fileInput.files[0].name
              : '';
            const label = document.getElementById('file-upload-label');
            if (label) {
              label.textContent = fileName || 'Select files...';
            }
          }}
        />
        <label
          htmlFor="file-upload"
          id="file-upload-label"
          className="mt-4 px-6 py-3 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-full text-midnight-coal dark:text-cadet-gray cursor-pointer"
        >
          Select files...
        </label>
      </div>
    </div>
  );
};

const ThumbnailUploadBox: React.FC<ThumbnailUploadBoxProps> = ({
  className,
  setThumbnail,
}) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setThumbnail(file);
    }
  };

  return (
    <div
      className={`bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg border-2 border-dotted border-midnight-coal dark:border-cadet-gray max-w-xs h-48 ${className}`}
    >
      <div className="flex flex-col items-center justify-center h-full">
        <div className="bg-desert-sand text-darker-gray rounded-full p-4">
          <Image className="w-8 h-8" />
        </div>
        <p className="text-midnight-coal dark:text-cadet-gray mt-2 text-center text-sm">
          Upload Thumbnail
        </p>
        <input
          type="file"
          className="hidden"
          id="thumbnail-upload"
          accept="image/*"
          onChange={handleFileChange}
        />
        <label
          htmlFor="thumbnail-upload"
          className="mt-2 px-4 py-2 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-full text-midnight-coal dark:text-cadet-gray cursor-pointer"
        >
          Select image...
        </label>
      </div>
    </div>
  );
};

export { UploadBox, ThumbnailUploadBox };
