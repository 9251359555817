import React, { useState } from 'react';
import { fetchVideos, Video } from '../assets/videoData';
import VideoDetailsModal from './VideoDetailsModal';

const MobileVideoList: React.FC = () => {
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const [videoData, setVideoData] = useState<Video[]>([]);
  const openModal = (video: Video) => {
    setSelectedVideo(video);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="">
      <div className="flex items-center bg-soft-cloud dark:bg-darker-gray rounded-t-lg shadow-lg">
        <div className="w-1/3 text-left text-base font-medium text-cadet-gray uppercase tracking-wider p-2">
          Thumbnail
        </div>
        <div className="w-2/3 text-left text-base font-medium text-cadet-gray uppercase tracking-wider p-2">
          Video title
        </div>
      </div>
      {videoData.map((video, index) => (
        <div
          key={index}
          className={`flex items-center ${
            index % 2 === 0
              ? 'bg-anti-flash-white dark:bg-dark-gray'
              : 'bg-soft-cloud dark:bg-darker-gray'
          } ${
            index === 0
              ? 'rounded-tl-lg rounded-tr-lg'
              : index === videoData.length - 1
                ? 'rounded-bl-lg rounded-br-lg'
                : ''
          } shadow-lg cursor-pointer`}
          onClick={() => openModal(video)}
        >
          <div className="w-1/3 p-2">
            <img
              src={video.thumbnail}
              alt={video.title}
              className="w-full h-auto object-cover rounded"
            />
          </div>
          <div className="w-2/3 p-2">
            <div className="text-base font-bold text-midnight-coal dark:text-soft-cloud">
              {video.title}
            </div>
          </div>
        </div>
      ))}
      {selectedVideo && (
        <VideoDetailsModal video={selectedVideo} onClose={closeModal} />
      )}
    </div>
  );
};

export default MobileVideoList;
