import React, { useState, useEffect } from 'react';
import {
  MessageCircle,
  ThumbsUp,
  ThumbsDown,
  Reply,
  ChevronDown,
  ChevronUp,
} from 'lucide-react';
import { fetchVideos, Video } from '../assets/videoData';

const CommentsPage: React.FC = () => {
  const [videoData, setVideoData] = useState<Video[]>([]);

  useEffect(() => {
    fetchVideos().then(setVideoData);
  }, []);

  return (
    <div className="relative h-full flex flex-col bg-anti-flash-white dark:bg-dark-gray p-4 sm:p-6">
      <div className="flex items-center justify-between mb-6 sm:mb-8">
        <h1 className="text-2xl sm:text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center">
          <MessageCircle className="w-6 h-6 sm:w-8 sm:h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
          Comments
        </h1>
      </div>

      <div className="space-y-8">
        <div className="bg-soft-cloud dark:bg-darker-gray rounded-lg p-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-light text-midnight-coal dark:text-cadet-gray flex items-center">
              <ChevronDown className="w-6 h-6 sm:w-8 sm:h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
              Latest
            </h2>
            <div className="flex space-x-2">
              {videoData.slice(0, 3).map((video, index) => (
                <img
                  key={index}
                  src={video.thumbnail}
                  alt="User"
                  className="w-10 h-10 rounded-full"
                />
              ))}
            </div>
          </div>

          <div className="space-y-4">
            {videoData.map((video, index) => (
              <div key={index} className="flex items-start space-x-4">
                <img
                  src={video.thumbnail}
                  alt="User"
                  className="w-10 h-10 rounded-full"
                />
                <div className="flex-grow">
                  <p className="text-midnight-coal dark:text-soft-cloud">
                    Aliquam fugit dolorem quod itaque consequatur laborum
                    praesentium et. Vel harum molestiae voluptatum minima.
                    Voluptatem sit officiis explicabo rerum non quod omnis
                    eveniet. Illum perspiciatis beatae nostrum libero porro
                    adipisci. Blanditiis dicta sed nemo commodi et a iure nisi.
                  </p>
                  <div className="flex items-center space-x-4 mt-2">
                    <button className="flex items-center text-cadet-gray dark:text-soft-cloud">
                      <Reply className="w-4 h-4 mr-1" />
                      Reply
                    </button>
                    <button className="flex items-center text-cadet-gray dark:text-soft-cloud">
                      <ThumbsUp className="w-4 h-4 mr-1" />
                      10
                    </button>
                    <button className="flex items-center text-cadet-gray dark:text-soft-cloud">
                      <ThumbsDown className="w-4 h-4 mr-1" />2
                    </button>
                    <button className="flex items-center text-cadet-gray dark:text-soft-cloud">
                      <MessageCircle className="w-4 h-4 mr-1" />2 replies
                    </button>
                  </div>
                </div>
                <img
                  src={video.thumbnail}
                  alt="Video"
                  className="w-20 h-20 rounded-lg"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="bg-soft-cloud dark:bg-darker-gray rounded-lg p-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-light text-midnight-coal dark:text-cadet-gray flex items-center">
              <ChevronUp className="w-6 h-6 sm:w-8 sm:h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
              Popular
            </h2>
          </div>

          <div className="space-y-4">
            {videoData.map((video, index) => (
              <div key={index} className="flex items-start space-x-4">
                <img
                  src={video.thumbnail}
                  alt="User"
                  className="w-10 h-10 rounded-full"
                />
                <div className="flex-grow">
                  <p className="text-midnight-coal dark:text-soft-cloud">
                    Aliquam fugit dolorem quod itaque consequatur laborum
                    praesentium et. Vel harum molestiae voluptatum minima.
                    Voluptatem sit officiis explicabo rerum non quod omnis
                    eveniet. Illum perspiciatis beatae nostrum libero porro
                    adipisci. Blanditiis dicta sed nemo commodi et a iure nisi.
                  </p>
                  <div className="flex items-center space-x-4 mt-2">
                    <button className="flex items-center text-cadet-gray dark:text-soft-cloud">
                      <Reply className="w-4 h-4 mr-1" />
                      Reply
                    </button>
                    <button className="flex items-center text-cadet-gray dark:text-soft-cloud">
                      <ThumbsUp className="w-4 h-4 mr-1" />
                      10
                    </button>
                    <button className="flex items-center text-cadet-gray dark:text-soft-cloud">
                      <ThumbsDown className="w-4 h-4 mr-1" />2
                    </button>
                    <button className="flex items-center text-cadet-gray dark:text-soft-cloud">
                      <MessageCircle className="w-4 h-4 mr-1" />2 replies
                    </button>
                  </div>
                </div>
                <img
                  src={video.thumbnail}
                  alt="Video"
                  className="w-20 h-20 rounded-lg"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsPage;
