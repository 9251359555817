import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const LoginPage: React.FC = () => {
  const { email, setEmail, password, setPassword, login } = useAuth();

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    // Call the login function from the useAuth hook
    login();
  };

  return (
    <div className="flex flex-col min-h-screen bg-anti-flash-white dark:bg-dark-gray">
      <Header />
      <div className="flex items-center justify-center flex-1">
        <div className="bg-soft-cloud dark:bg-darker-gray p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-3xl font-medium text-midnight-coal dark:text-cadet-gray mb-6 text-center">
            Login
          </h2>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label
                className="block text-midnight-coal dark:text-cadet-gray mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-lg"
                required
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-midnight-coal dark:text-cadet-gray mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 bg-soft-cloud dark:bg-darker-gray border border-midnight-coal dark:border-cadet-gray rounded-lg"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full p-3 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-lg font-medium"
            >
              Log In
            </button>
          </form>
          <div className="mt-4 text-center">
            <p className="text-midnight-coal dark:text-cadet-gray">
              Don't have an account?{' '}
              <Link to="/register" className="text-cadet-gray hover:underline">
                Register
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
