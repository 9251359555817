import { useState } from 'react';
import {
  signIn,
  signUp,
  sendVerificationEmail,
} from '../services/SuperTokensService';

export const useAuth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const login = async () => {
    console.log('Logging in with:', { email, password });
    const response = await signIn(email, password);
    console.log('Response:', { response });
    if (response.status === 'OK') {
      window.location.href = '/dashboard';
    } else {
      console.error('Login failed:', response.status);
    }
  };

  const register = () => {
    console.log('Registering with:', { email, password, confirmPassword });
    const response = signUp(email, password);
    sendVerificationEmail();
    console.log('signUpResp', response);

    const backendApiUrl =
      process.env.REACT_APP_API_URL || 'http://localhost:4000';
    const url = `${backendApiUrl}/user/createAccount`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
      credentials: 'include',
    });
  };

  return {
    email,
    password,
    confirmPassword,
    setEmail,
    setPassword,
    setConfirmPassword,
    login,
    register,
  };
};
