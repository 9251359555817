import React from 'react';
import { Upload, XCircle } from 'lucide-react';
import { useFooterActions } from '../hooks/useFooterActions';
import { UploadData } from '../types/uploadData';

interface FoldOutFooterProps {
  toggleMenu: () => void;
  upload: UploadData;
}

const FoldOutFooter: React.FC<FoldOutFooterProps> = ({
  toggleMenu,
  upload
}) => {
  const { handleUpload, handleCancel, isUploading } =
    useFooterActions(toggleMenu);

  return (
    <div className="flex items-center justify-end p-6 bg-anti-flash-white dark:bg-dark-gray space-x-4">
      {/* Cancel Button */}
      <button
        onClick={handleCancel}
        aria-label="Cancel"
        className="group flex items-center justify-center px-4 py-3 rounded-full bg-soft-cloud dark:bg-darker-gray hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-200"
      >
        <XCircle className="w-6 h-6 text-midnight-coal dark:text-soft-cloud group-hover:text-soft-cloud dark:group-hover:text-darker-gray" />
        <span className="ml-2 text-midnight-coal dark:text-soft-cloud group-hover:text-soft-cloud dark:group-hover:text-darker-gray font-medium">
          Cancel
        </span>
      </button>

      <button
        onClick={() => {
          // param
          handleUpload(upload);
        }}
        aria-label="Upload"
        className={`group flex items-center justify-center px-6 py-3 rounded-full ${
          isUploading
            ? 'bg-cadet-gray cursor-not-allowed'
            : 'bg-midnight-coal hover:bg-cadet-gray dark:bg-cadet-gray dark:hover:bg-midnight-coal'
        } text-soft-cloud dark:text-darker-gray font-semibold transition-colors duration-200`}
        disabled={isUploading}
      >
        <Upload className="w-6 h-6 text-soft-cloud dark:text-darker-gray group-hover:text-anti-flash-white" />
        <span className="ml-2 group-hover:text-anti-flash-white dark:group-hover:text-darker-gray font-medium">
          {isUploading ? 'Publishing...' : 'Publish'}
        </span>
      </button>
    </div>
  );
};

export default FoldOutFooter;
