import React from 'react';
import { Upload } from 'lucide-react';

const UploadButton: React.FC<{
  handleUpload: () => void;
}> = ({ handleUpload }) => {
  return (
    <div className="flex space-x-4">
      <button
        onClick={handleUpload}
        className="flex items-center justify-center bg-soft-cloud dark:bg-cadet-gray text-midnight-coal dark:text-darker-gray py-2 px-6 rounded-md shadow h-16 text-lg font-medium
                   transition-all duration-200 ease-in-out
                   hover:bg-cadet-gray hover:text-soft-cloud
                   dark:hover:bg-darker-gray dark:hover:text-cadet-gray
                   hover:shadow-md"
      >
        <Upload className="w-8 h-8 mr-2" />
        Upload
      </button>
    </div>
  );
};

export default UploadButton;
