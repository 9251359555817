import { useState, useEffect } from 'react';
import { SiX, SiVimeo, SiYoutube } from 'react-icons/si'; // Import platform icons

const backendApiUrl = process.env.REACT_APP_API_URL;

export type Channel = {
  id: string;
  name: string;
  platform: string;
};

export type Platform = {
  name: string;
  connected: boolean;
  channel: Channel[];
  IconComponent: React.FC<React.SVGProps<SVGSVGElement>>;
};

const initialPlatforms: Platform[] = [
  {
    name: 'YouTube',
    connected: false,
    channel: [],
    IconComponent: SiYoutube,
  },
  {
    name: 'X',
    connected: false,
    channel: [],
    IconComponent: SiX,
  },
  {
    name: 'Vimeo',
    connected: false,
    channel: [],
    IconComponent: SiVimeo,
  },
];

export const usePlatforms = () => {
  const [platformData, setPlatformData] = useState<Platform[]>(initialPlatforms);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await fetch(`${backendApiUrl}/channel`, {
          method: 'GET',
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error('Failed to fetch channels');
        }
        const data = await response.json();
        
        const updatedPlatforms = platformData.map(platform => ({
          ...platform,
          channel: data.channels.filter((channel: Channel) => channel.platform === platform.name),
          connected: data.channels.some((channel: Channel) => channel.platform === platform.name),
        }));

        setPlatformData(updatedPlatforms);
      } catch (error) {
        console.error('Error fetching channels:', error);
      }
    };

    fetchChannels();
  }, []);

  const handleConnect = (index: number) => {
    const updatedPlatforms = [...platformData];
    updatedPlatforms[index].connected = !updatedPlatforms[index].connected;

    if (!updatedPlatforms[index].connected) {
      updatedPlatforms[index].channel = [];
    }

    setPlatformData(updatedPlatforms);
  };

  const createNewChannel = async (platformName: string) => {
    try {
      const response = await fetch(`${backendApiUrl}/channel/authNew`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          platform: platformName,
          name: `New ${platformName} Account`,
          description: `New ${platformName} Account`,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create new account');
      }

      const data = await response.json();
      
      const updatedPlatforms = [...platformData];
      const platformIndex = updatedPlatforms.findIndex(p => p.name === platformName);
      
      if (platformIndex !== -1) {
        updatedPlatforms[platformIndex].connected = true;
        updatedPlatforms[platformIndex].channel.push(data.channel);
        setPlatformData(updatedPlatforms);
      }

      if (data.oauthUrl) {
        window.location.href = data.oauthUrl;
      }

    } catch (error) {
      console.error('Error creating new account:', error);
    }
  };

  const handleConnectMore = (index: number) => {
    const platformName = platformData[index].name;
    createNewChannel(platformName);
  };

  const disconnectChannel = async (platformIndex: number, channelIndex: number) => {
    try {
      const channelToDisconnect = platformData[platformIndex].channel[channelIndex];
      const response = await fetch(`${backendApiUrl}/channel?id=${channelToDisconnect.id}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to disconnect channel');
      }

      const updatedPlatforms = [...platformData];
      updatedPlatforms[platformIndex].channel.splice(channelIndex, 1);

      if (updatedPlatforms[platformIndex].channel.length === 0) {
        updatedPlatforms[platformIndex].connected = false;
      }

      setPlatformData(updatedPlatforms);
    } catch (error) {
      console.error('Error disconnecting channel:', error);
    }
  };

  return {
    platformData,
    handleConnect,
    handleConnectMore,
    disconnectChannel,
  };
};
