import React from 'react';
import { LayoutGrid, UploadCloud, Link2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const DashboardPage: React.FC = () => {
  const navigate = useNavigate();

  const hasAuthenticatedPlatforms = true;

  const handleUploadClick = () => {
    navigate('/videos', { state: { openMenu: true } });
  };

  const handleConnectPlatformsClick = () => {
    navigate('/platforms');
  };

  return (
    <div className="relative h-full flex flex-col bg-anti-flash-white dark:bg-dark-gray p-4 sm:p-6">
      <div className="flex items-center justify-between mb-6 sm:mb-8">
        <h1 className="text-2xl sm:text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center">
          <LayoutGrid className="w-6 h-6 sm:w-8 sm:h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
          Dashboard
        </h1>
      </div>

      {/* Grid container for the cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 justify-items-start">
        {/* Conditional rendering based on platform authentication status */}
        {hasAuthenticatedPlatforms ? (
          <div className="flex items-center justify-center w-full h-64 sm:h-72 lg:h-80 bg-soft-cloud dark:bg-darker-gray border-2 border-dashed border-cadet-gray dark:border-dark-gray rounded-lg max-w-md">
            <div className="text-center space-y-4">
              <UploadCloud className="w-12 h-12 sm:w-16 sm:h-16 text-cadet-gray mx-auto" />
              <p className="text-midnight-coal dark:text-soft-cloud text-base sm:text-lg">
                Drag & drop video file(s) to upload.
              </p>
              <button
                onClick={handleUploadClick}
                className="px-4 py-2 sm:px-6 sm:py-2 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud rounded-lg"
              >
                Select files...
              </button>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-64 sm:h-72 lg:h-80 bg-soft-cloud dark:bg-darker-gray border-2 border-dashed border-cadet-gray dark:border-dark-gray rounded-lg max-w-md">
            <div className="text-center space-y-4">
              <Link2 className="w-12 h-12 sm:w-16 sm:h-16 text-cadet-gray mx-auto" />
              <p className="text-midnight-coal dark:text-soft-cloud text-base sm:text-lg">
                You don't have any platforms authorized yet!
              </p>
              <button
                onClick={handleConnectPlatformsClick}
                className="px-4 py-2 sm:px-6 sm:py-2 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud rounded-lg"
              >
                Connect some
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
