import React from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  confirmText: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-soft-cloud dark:bg-darker-gray p-6 rounded-lg max-w-sm w-full">
        <h2 className="text-xl font-bold mb-4 text-midnight-coal dark:text-soft-cloud">
          {title}
        </h2>
        <p className="mb-6 text-cadet-gray">{message}</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-soft-cloud dark:bg-dark-gray text-midnight-coal dark:text-soft-cloud rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-desert-sand dark:bg-cadet-gray text-midnight-coal dark:text-soft-cloud rounded-lg"
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
