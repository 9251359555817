import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardPage from './pages/DashboardPage';
import ColorsPage from './pages/ColorsPage';
import VideosPage from './pages/VideosPage';
import PlatformsPage from './pages/PlatformsPage';
import SettingsPage from './pages/SettingsPage';
import Layout from './components/Layout';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import { useDarkMode } from './hooks/useDarkMode';
import CommentsPage from './pages/CommentsPage';

export const validRoutes = [
  '/dashboard',
  '/videos',
  '/platforms',
  '/settings',
  '/login',
  '/register',
  '/colors',
  '/comments',
];

const App: React.FC = () => {
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <div className="min-h-screen bg-anti-flash-white dark:bg-dark-gray">
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <DashboardPage />
              </Layout>
            }
          />
          <Route
            path="/colors"
            element={
              <Layout>
                <ColorsPage />
              </Layout>
            }
          />
          <Route
            path="/videos"
            element={
              <Layout>
                <VideosPage />
              </Layout>
            }
          />
          <Route
            path="/platforms"
            element={
              <Layout>
                <PlatformsPage />
              </Layout>
            }
          />
          <Route
            path="/settings"
            element={
              <Layout>
                <SettingsPage />
              </Layout>
            }
          />
          <Route
            path="/comments"
            element={
              <Layout>
                <CommentsPage />
              </Layout>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
