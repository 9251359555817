import React from 'react';
import TitleAndDescription from './TitleAndDescription';
import TagsInput from './TagsInput';
import Visibility from './Visibility';
import SchedulePicker from './SchedulePicker';
import ChannelPicker from './ChannelPicker';
import Advanced from './Advanced';
import { useState } from 'react';
import { Channel } from '../types/channels';

interface VideoFormContentProps {
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  title: string;
  description: string;
  channels: Channel[],
  setChannels: React.Dispatch<React.SetStateAction<Channel[]>>
}

const VideoFormContent: React.FC<VideoFormContentProps> = ({
  setTitle,
  setDescription,
  title,
  description,
  channels,
  setChannels
}) => {


  return (
    <div className="bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg">
      <div className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <TitleAndDescription
            setTitle={setTitle}
            setDescription={setDescription}
            title={title}
            description={description}
          />
          <div className="mt-6">
            <TagsInput />
          </div>

          <div className="mt-6 flex flex-col gap-4">
            <Visibility />
            <div className="mt-4">
              <SchedulePicker />
            </div>
          </div>
        </div>

        <div>
          <ChannelPicker setChannels={setChannels} channels={channels} />
          <div className="mt-6">
            <Advanced /> {/* Added the Advanced component here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoFormContent;
