import { useState } from 'react';

export const useAccount = () => {
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);

  const changeEmail = () => {
    console.log('Sending email change request...');
    setEmailModalOpen(true);
  };

  const changePassword = () => {
    console.log('Sending password change request...');
    setPasswordModalOpen(true);
  };

  return {
    isEmailModalOpen,
    setEmailModalOpen,
    isPasswordModalOpen,
    setPasswordModalOpen,
    changeEmail,
    changePassword,
  };
};
