import SuperTokens from 'supertokens-web-js';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import EmailVerification from 'supertokens-web-js/recipe/emailverification';
import Session from 'supertokens-web-js/recipe/session';

interface FormField {
  id: string;
  value?: string;
  error?: string;
}

interface Response {
  status: string;
  formFields?: FormField[];
  reason?: string;
}

function checkForFormFieldError(response: Response): void {
  if (response.status === 'FIELD_ERROR' && response.formFields) {
    response.formFields.forEach((formField) => {
      if (formField.error) {
        throw new Error(formField.error);
      }
    });
  }
}

function checkForError(response: Response, error: string, errorMessage: string): void {
  if (response.status === error) {
    throw new Error(errorMessage);
  }
}

function checkForReasonError(response: Response, error: string): void {
  checkForError(response, error, response.reason || '');
}

export const init = async (): Promise<void> => {
  SuperTokens.init({
    appInfo: {
      apiDomain: 'http://localhost:4000',
      apiBasePath: '/auth',
      appName: 'SubSynced',
    },
    recipeList: [
      EmailVerification.init(),
      Session.init(),
      EmailPassword.init(),
    ],
  });
};

export const signUp = async (email: string, password: string): Promise<Response> => {
  try {
    const response = await EmailPassword.signUp({
      formFields: [
        {
          id: 'email',
          value: email,
        },
        {
          id: 'password',
          value: password,
        },
      ],
    });
    checkForFormFieldError(response);
    checkForReasonError(response, 'SIGN_UP_NOT_ALLOWED');

    return response;
  } catch (error) {
    console.error('Login request failed:', error instanceof Error ? error.message : error);
    throw error;
  }
};

export const signIn = async (email: string, password: string): Promise<Response> => {
  try {
    const response = await EmailPassword.signIn({
      formFields: [
        {
          id: 'email',
          value: email,
        },
        {
          id: 'password',
          value: password,
        },
      ],
    });

    console.log('Sign in response:', response);
    checkForFormFieldError(response);
    checkForReasonError(response, 'SIGN_IN_NOT_ALLOWED');
    checkForError(response, 'WRONG_CREDENTIALS_ERROR', 'Email password combination is incorrect.');
    return response;
  } catch (error) {
    console.error('Login request failed:', error instanceof Error ? error.message : error);
    throw error;
  }
};

export const signOut = async (): Promise<void> => {
  try {
    await Session.signOut();
  } catch (error) {
    console.error('Logout request failed:', error instanceof Error ? error.message : error);
    throw error;
  }
};

export const sendVerificationEmail = async (): Promise<Response> => {
  try {
    const response = await EmailVerification.sendVerificationEmail();
    checkForError(response, 'EMAIL_ALREADY_VERIFIED_ERROR', 'Email already verified.');
    return response;
  } catch (error) {
    console.error('Failed to resend verification email:', error instanceof Error ? error.message : error);
    throw error;
  }
};

export const verifyEmail = async (): Promise<Response> => {
  try {
    const response = await EmailVerification.verifyEmail();
    checkForError(response, 'EMAIL_VERIFICATION_INVALID_TOKEN_ERROR', 'Invalid token.');
    return response;
  } catch (error) {
    console.error('Failed to verify email:', error instanceof Error ? error.message : error);
    throw error;
  }
};

export const sendPasswordResetEmail = async (email: string): Promise<Response> => {
  try {
    const response = await EmailPassword.sendPasswordResetEmail({
      formFields: [
        {
          id: 'email',
          value: email,
        },
      ],
    });

    checkForFormFieldError(response);
    return response;
  } catch (error) {
    console.error('Send password reset email failed:', error instanceof Error ? error.message : error);
    throw error;
  }
};

export const submitNewPassword = async (password: string): Promise<Response> => {
  try {
    const response = await EmailPassword.submitNewPassword({
      formFields: [
        {
          id: 'password',
          value: password,
        },
      ],
    });

    checkForFormFieldError(response);
    checkForError(response, 'RESET_PASSWORD_INVALID_TOKEN_ERROR', 'Password reset failed. Invalid token.');
    return response;
  } catch (error) {
    console.error('Submitting new password failed:', error instanceof Error ? error.message : error);
    throw error;
  }
};

export const sessionExists = async (): Promise<boolean> => {
  return await Session.doesSessionExist();
};
