import React from 'react';
import { useSidebar } from '../hooks/useSidebar';
import { useNavigate } from 'react-router-dom';

const MobileMenu: React.FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
  const { icons, getActiveStatus, isRouteValid } = useSidebar();
  const navigate = useNavigate();

  const handleMenuClick = (path: string) => {
    if (isRouteValid(path)) {
      navigate(path);
      closeMenu(); // Close the menu after navigation
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex">
      <div className="bg-anti-flash-white dark:bg-dark-gray w-3/5 p-6 flex flex-col justify-start">
        {/* Menu Options */}
        <div className="mt-8 flex flex-col space-y-6">
          {icons.map(({ id, path, IconComponent }) => {
            const isActive = getActiveStatus(path);
            const isValid = isRouteValid(path);

            return (
              <div
                key={id}
                className={`flex items-center space-x-4 p-4 rounded-lg text-lg font-bold ${
                  isActive
                    ? 'bg-midnight-coal text-anti-flash-white dark:bg-cadet-gray dark:text-darker-gray'
                    : isValid
                      ? 'hover:bg-midnight-coal hover:text-anti-flash-white dark:hover:bg-cadet-gray dark:hover:text-darker-gray cursor-pointer text-midnight-coal dark:text-cadet-gray'
                      : 'opacity-50 cursor-not-allowed text-midnight-coal dark:text-cadet-gray'
                }`}
                onClick={() => handleMenuClick(path)} // Handle click for valid links
              >
                <IconComponent
                  className={`w-8 h-8 ${isActive ? 'text-anti-flash-white dark:text-darker-gray' : 'text-midnight-coal dark:text-cadet-gray'}`}
                />
                <span
                  className={`text-lg font-bold ${isActive ? 'text-anti-flash-white dark:text-darker-gray' : 'text-midnight-coal dark:text-cadet-gray'}`}
                >
                  {id}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      {/* Overlay to handle clicks outside the menu */}
      <div className="flex-1 bg-black bg-opacity-50" onClick={closeMenu}></div>
    </div>
  );
};

export default MobileMenu;
