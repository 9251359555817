import React from 'react';
import { Link } from 'react-router-dom';
import { Home } from 'lucide-react';

const NotFoundPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-anti-flash-white dark:bg-dark-gray">
      <h1 className="text-4xl font-bold text-midnight-coal dark:text-cadet-gray mb-4">
        404 - Page Not Found
      </h1>
      <p className="text-lg text-cadet-gray dark:text-soft-cloud mb-8">
        Oops! The page you're looking for doesn't exist.
      </p>
      <Link
        to="/dashboard"
        className="flex items-center px-6 py-3 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-lg hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-300"
      >
        <Home className="w-5 h-5 mr-2" />
        Go to Dashboard
      </Link>
    </div>
  );
};

export default NotFoundPage;
