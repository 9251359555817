import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  ReactNode,
} from 'react';

import {
  signIn,
  signOut,
  sendVerificationEmail,
  sessionExists,
} from '../services/SuperTokensService';
import {
  changeEmail as userChangeEmail,
  deleteUser,
  getUserInfo,
} from '../services/UserService';

interface User {
  isAuthenticated: boolean;
  userId: string | null;
  username: string;
  email: string;
  emailVerified: boolean;
  platforms: {
    youtube: boolean;
    vimeo: boolean;
    x: boolean;
  };
}

interface UserContextType {
  user: User;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  changeEmail: (email: string) => Promise<void>;
  deleteAccount: () => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User>({
    isAuthenticated: false,
    userId: null,
    username: '',
    email: '',
    emailVerified: false,
    platforms: {
      youtube: false,
      vimeo: false,
      x: false,
    },
  });
  const [isLoading, setIsLoading] = useState(true);

  const checkAuthStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      if (await sessionExists()) {
        const userInfo = await getUserInfo();
        const user: User = {
          isAuthenticated: true,
          userId: userInfo.userId,
          username: userInfo.username,
          email: userInfo.email,
          emailVerified: userInfo.emailVerified,
          platforms: {
            youtube: userInfo.platforms.youtube,
            vimeo: userInfo.platforms.vimeo,
            x: userInfo.platforms.x,
          },
        };
        setUser(user);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const login = async (email: string, password: string) => {
    await signIn(email, password);
    checkAuthStatus();
  };

  const logout = async () => {
    await signOut();
    window.location.href = '/';
  };

  const changeEmail = async (email: string) => {
    try {
      await userChangeEmail(email);
      checkAuthStatus();
    } catch (error) {
      if (error instanceof Error) {
        console.error('Change Email Error:', error.message);
      } else {
        console.error('Change Email Error:', error);
      }
    }
  };

  const deleteAccount = async () => {
    try {
      await deleteUser();
      logout();
    } catch (error) {
      if (error instanceof Error) {
        console.error('Delete Account Error:', error.toString());
      } else {
        console.error('Delete Account Error:', String(error));
      }
    }
  };

  const resendVerificationEmail = async () => {
    await sendVerificationEmail();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading,
        login,
        logout,
        changeEmail,
        deleteAccount,
        resendVerificationEmail,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
