import React, { useState } from 'react';
import Header from './Header';
import MobileHeader from './MobileHeader';
import MobileMenu from './MobileMenu';
import Sidebar from './Sidebar'; // Desktop sidebar

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

  return (
    <div className="flex flex-col min-h-screen bg-anti-flash-white dark:bg-dark-gray">
      {/* Desktop Header */}
      <div className="hidden sm:block">
        <Header />
      </div>
      {/* Mobile Header */}
      <div className="sm:hidden">
        <MobileHeader toggleMobileMenu={toggleMobileMenu} />
      </div>
      <div className="flex flex-1">
        {/* Desktop Sidebar */}
        <div className="hidden sm:flex flex-col items-center w-24 p-4 bg-anti-flash-white dark:bg-dark-gray mr-4">
          <Sidebar />
        </div>
        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <MobileMenu closeMenu={() => setMobileMenuOpen(false)} />
        )}
        {/* Main Content */}
        <div className="flex-1">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
