const backendApiUrl = process.env.REACT_APP_API_URL;
export interface Video {
  id: string;
  thumbnail: string;
  title: string;
  description: string;
  channelGroup: string;
  uploadDate: string;
  views: string;
  likes: string;
  dislikes: string;
  revenue: string;
  comments: string;
  alt: string;
  platforms: string[];
}

export interface VideoResponse {
  itemCount: number;
  totalItemCount: number;
  totalPageCount: number;
  results: Video[];
}

export async function fetchVideos(): Promise<Video[]> {
  try {
    const response = await fetch(`${backendApiUrl}/video`);
    if (!response.ok) {
      throw new Error('Failed to fetch videos');
    }
    const data: VideoResponse = await response.json();
    console.log('Raw data:', data);
    const videos = data.results.map((item: any): Video => ({
      id: item.id || '',
      thumbnail: item.thumnailUrl || '/defaultThumbnail.png',
      title: item.title || '',
      description: item.description || '',
      channelGroup: item.platforms[0] || '',
      uploadDate: item.createdAt || '',
      views: '',
      likes: '',
      dislikes: '',
      revenue: '',
      comments: '',
      alt: item.alt || '',
      platforms: item.platforms || [],
    }));
    console.log('Fetched videos:', videos);
    return videos;
  } catch (error) {
    console.error('Error fetching videos:', error);
    return [];
  }
}
