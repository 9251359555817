import React, { useState, useEffect } from 'react';
import { FileVideo, Upload } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import SortBar from '../components/SortBar';
import ViewBar from '../components/ViewBar';
import MobileSortBar from '../components/MobileSortBar';
import MobileViewBar from '../components/MobileViewBar';
import UploadButton from '../components/UploadButton';
import VideoList from '../components/VideoList';
import VideoGrid from '../components/VideoGrid';
import MobileVideoList from '../components/MobileVideoList';
import MobileVideoGrid from '../components/MobileVideoGrid';
import MultiselectBar from '../components/MultiselectBar';
import FoldOutMenu from '../components/FoldOutMenu';

const VideosPage: React.FC = () => {
  const location = useLocation();
  const [isFoldOutMenuOpen, setIsFoldOutMenuOpen] = useState(false);

  // Use local storage to persist the view, default to 'grid'
  const [activeView, setActiveView] = useState(() => {
    const savedView = localStorage.getItem('activeView');
    return savedView || 'grid';
  });

  useEffect(() => {
    if (location.state?.openMenu) {
      setIsFoldOutMenuOpen(true);
    }
  }, [location.state]);

  // Update local storage when activeView changes
  useEffect(() => {
    localStorage.setItem('activeView', activeView);
  }, [activeView]);

  const toggleFoldOutMenu = () => setIsFoldOutMenuOpen(!isFoldOutMenuOpen);

  const handleUpload = () => {
    toggleFoldOutMenu();
  };

  return (
    <div className="relative h-full flex flex-col bg-anti-flash-white dark:bg-dark-gray">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 sm:mb-8 p-4 sm:p-6">
        <h1 className="text-2xl sm:text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center mb-4 sm:mb-0">
          <FileVideo className="w-6 h-6 sm:w-8 sm:h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
          Videos
        </h1>

        {/* Desktop controls - unchanged */}
        <div className="hidden sm:flex space-x-4 items-center">
          <SortBar />
          <div className="flex space-x-4 items-center">
            <ViewBar activeView={activeView} setActiveView={setActiveView} />
            <UploadButton handleUpload={handleUpload} />
          </div>
        </div>

        {/* Mobile controls */}
        <div className="sm:hidden w-full flex items-center space-x-2">
          <MobileSortBar />
          <MobileViewBar
            activeView={activeView}
            setActiveView={setActiveView}
          />
          <button
            onClick={toggleFoldOutMenu}
            className="p-2 h-10 bg-soft-cloud dark:bg-darker-gray text-midnight-coal dark:text-soft-cloud rounded-md shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none"
          >
            <Upload className="w-5 h-5" />
          </button>
        </div>
      </div>
      <div
        className={`flex-1 ${isFoldOutMenuOpen ? 'opacity-50' : 'opacity-100'}`}
      >
        <div className="hidden sm:block">
          {activeView === 'list' ? <VideoList /> : <VideoGrid />}
        </div>
        <div className="block sm:hidden">
          {activeView === 'list' ? <MobileVideoList /> : <MobileVideoGrid />}
        </div>
      </div>
      <footer
        className={`hidden sm:flex mt-8 justify-center ${isFoldOutMenuOpen ? 'opacity-50' : 'opacity-100'}`}
      >
        <MultiselectBar />
      </footer>
      <FoldOutMenu isOpen={isFoldOutMenuOpen} toggleMenu={toggleFoldOutMenu} />
    </div>
  );
};

export default VideosPage;
