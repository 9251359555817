import React, { useState, useEffect } from 'react';
import { fetchVideos, Video } from '../assets/videoData';
import { Eye } from 'lucide-react';
import VideoDetailsModal from './VideoDetailsModal';

const MobileVideoGrid: React.FC = () => {
  const [videoData, setVideoData] = useState<Video[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

  const openModal = (video: Video) => {
    setSelectedVideo(video);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  useEffect(() => {
    fetchVideos().then(setVideoData);
  }, []);

  return (
    <div className="grid grid-cols-2 gap-4 p-4">
      {videoData.map((video, index) => (
        <div
          key={index}
          className="bg-soft-cloud dark:bg-darker-gray rounded-lg shadow-lg overflow-hidden cursor-pointer"
          onClick={() => openModal(video)}
        >
          <div className="aspect-w-16 aspect-h-9">
            <img
              src={video.thumbnail}
              alt={video.title}
              className="object-cover w-full h-full"
            />
          </div>
          <div className="p-2">
            <h2 className="text-sm font-semibold text-midnight-coal dark:text-soft-cloud mb-1 truncate">
              {video.title}
            </h2>
            <div className="flex items-center text-cadet-gray text-xs">
              <Eye className="w-3 h-3 mr-1" />
              {video.views}
            </div>
          </div>
        </div>
      ))}
      {selectedVideo && (
        <VideoDetailsModal video={selectedVideo} onClose={closeModal} />
      )}
    </div>
  );
};

export default MobileVideoGrid;
