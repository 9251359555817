import React from 'react';

const Header: React.FC = () => {
  return (
    <div className="flex items-center justify-between p-4 bg-anti-flash-white dark:bg-dark-gray">
      <div className="flex items-center ml-4">
        <img src="/logo.svg" alt="Logo" className="w-14 h-14 mr-3" />
        <h1 className="text-4xl font-bold text-midnight-coal">Sub</h1>
        <h1 className="text-4xl font-bold text-cadet-gray">Synced</h1>
      </div>
    </div>
  );
};

export default Header;
