import React, { useState } from 'react';
import { Settings, Mail, Lock, Sliders, User } from 'lucide-react';
import { useDarkMode } from '../hooks/useDarkMode';
import { useAccount } from '../hooks/useAccount';
import Modal from '../components/Modal';

const SettingsPage: React.FC = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const {
    isEmailModalOpen,
    isPasswordModalOpen,
    setEmailModalOpen,
    setPasswordModalOpen,
    changeEmail,
    changePassword,
  } = useAccount();

  const [newEmail, setNewEmail] = useState('');
  const [confirmNewEmail, setConfirmNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const currentEmail = 'example@email.com'; // Example data

  const handleEmailChange = (e: React.FormEvent) => {
    e.preventDefault();
    if (newEmail === confirmNewEmail) {
      changeEmail();
    } else {
      alert('New email and confirmation do not match');
    }
  };

  const handlePasswordChange = (e: React.FormEvent) => {
    e.preventDefault();
    changePassword();
  };

  return (
    <div className="relative h-full flex flex-col bg-anti-flash-white dark:bg-dark-gray p-6">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-light text-midnight-coal dark:text-cadet-gray flex items-center">
          <Settings className="w-8 h-8 text-midnight-coal dark:text-cadet-gray mr-2" />
          Settings
        </h1>
      </div>

      <div className="w-full max-w-2xl space-y-8">
        {/* General Settings */}
        <div>
          <h2 className="text-xl font-medium text-midnight-coal dark:text-cadet-gray flex items-center mb-4">
            <Sliders className="w-6 h-6 mr-2" />
            General
          </h2>
          <div className="bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg shadow-lg">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-lg font-medium text-midnight-coal dark:text-cadet-gray">
                  Dark Mode
                </h3>
                <p className="text-sm text-cadet-gray">
                  Toggle dark mode on or off
                </p>
              </div>

              {/* Toggle Switch */}
              <div
                onClick={toggleDarkMode}
                className={`w-12 h-6 flex items-center bg-${
                  isDarkMode
                    ? 'midnight-coal dark:bg-cadet-gray'
                    : 'cadet-gray dark:bg-dark-gray'
                } rounded-full p-1 cursor-pointer transition-colors duration-300`}
              >
                <div
                  className={`bg-${isDarkMode ? 'anti-flash-white' : 'soft-cloud'} w-4 h-4 rounded-full shadow-md transform ${
                    isDarkMode ? 'translate-x-6' : 'translate-x-0'
                  } transition-transform duration-300`}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {/* Account Settings */}
        <div>
          <h2 className="text-xl font-medium text-midnight-coal dark:text-cadet-gray flex items-center mb-4">
            <User className="w-6 h-6 mr-2" />
            Account
          </h2>

          {/* Email Settings */}
          <div className="bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg shadow-lg mb-4">
            <h3 className="text-lg font-medium text-midnight-coal dark:text-cadet-gray flex items-center mb-4">
              <Mail className="w-5 h-5 mr-2" />
              Change Email
            </h3>
            <p className="text-cadet-gray mb-4">
              Current email: <span className="font-medium">{currentEmail}</span>
            </p>
            <form onSubmit={handleEmailChange} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-cadet-gray mb-1">
                  New Email
                </label>
                <input
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  required
                  className="w-full p-2 bg-anti-flash-white dark:bg-dark-gray border border-cadet-gray rounded-md text-midnight-coal dark:text-soft-cloud"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-cadet-gray mb-1">
                  Confirm New Email
                </label>
                <input
                  type="email"
                  value={confirmNewEmail}
                  onChange={(e) => setConfirmNewEmail(e.target.value)}
                  required
                  className="w-full p-2 bg-anti-flash-white dark:bg-dark-gray border border-cadet-gray rounded-md text-midnight-coal dark:text-soft-cloud"
                />
              </div>
              <button
                type="submit"
                className="w-full p-3 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-lg font-medium hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-300"
              >
                Change Email
              </button>
            </form>
          </div>

          {/* Password Settings */}
          <div className="bg-soft-cloud dark:bg-darker-gray p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-medium text-midnight-coal dark:text-cadet-gray flex items-center mb-4">
              <Lock className="w-5 h-5 mr-2" />
              Change Password
            </h3>
            <form onSubmit={handlePasswordChange} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-cadet-gray mb-1">
                  Current Password
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                  className="w-full p-2 bg-anti-flash-white dark:bg-dark-gray border border-cadet-gray rounded-md text-midnight-coal dark:text-soft-cloud"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-cadet-gray mb-1">
                  New Password
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  className="w-full p-2 bg-anti-flash-white dark:bg-dark-gray border border-cadet-gray rounded-md text-midnight-coal dark:text-soft-cloud"
                />
              </div>
              <button
                type="submit"
                className="w-full p-3 bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-lg font-medium hover:bg-cadet-gray dark:hover:bg-midnight-coal transition-colors duration-300"
              >
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Email Modal */}
      <Modal
        isOpen={isEmailModalOpen}
        onClose={() => setEmailModalOpen(false)}
        onConfirm={() => {
          /* Handle email change confirmation */
        }}
        title="Email Change Request Sent"
        message="Please check your email to proceed with changing your email address."
        confirmText="OK"
      />

      {/* Password Modal */}
      <Modal
        isOpen={isPasswordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
        onConfirm={() => {
          /* Handle password change confirmation */
        }}
        title="Password Change Request Sent"
        message="Please check your email to proceed with changing your password."
        confirmText="OK"
      />
    </div>
  );
};

export default SettingsPage;
