import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSidebar } from '../hooks/useSidebar';

const Sidebar: React.FC = () => {
  const { icons, getActiveStatus, isRouteValid } = useSidebar();
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  return (
    <div className="bg-soft-cloud dark:bg-darker-gray p-3 rounded-lg shadow-lg relative">
      <div className="flex flex-col space-y-4 text-cadet-gray relative">
        {icons.map(({ id, path, IconComponent }) => {
          const isActive = getActiveStatus(path);
          const isValid = isRouteValid(path);

          const content = (
            <div
              className={`group p-2 rounded-md relative ${
                isActive
                  ? 'bg-midnight-coal dark:bg-cadet-gray'
                  : isValid
                    ? 'hover:bg-midnight-coal dark:hover:bg-cadet-gray'
                    : 'opacity-50'
              } ${isValid ? 'cursor-pointer' : 'cursor-not-allowed'}`}
              onMouseEnter={() => setHoveredIcon(id)}
              onMouseLeave={() => setHoveredIcon(null)}
            >
              {isActive && isValid && (
                <div className="absolute -right-1.5 top-1.5 bottom-1.5 w-1 bg-midnight-coal dark:bg-cadet-gray rounded-r-full"></div>
              )}
              <IconComponent
                className={`w-6 h-6 ${
                  isActive
                    ? 'text-anti-flash-white dark:text-darker-gray'
                    : 'group-hover:text-anti-flash-white dark:group-hover:text-darker-gray'
                }`}
              />

              {/* Tooltip displayed to the right of the icon */}
              {hoveredIcon === id && isValid && (
                <div className="absolute left-12 top-1/2 transform -translate-y-1/2 px-2 py-1 text-xs bg-midnight-coal dark:bg-cadet-gray text-soft-cloud dark:text-darker-gray rounded-md z-50 whitespace-nowrap">
                  {id.charAt(0).toUpperCase() + id.slice(1)}
                </div>
              )}
            </div>
          );

          return isValid ? (
            <Link to={path} key={id}>
              {content}
            </Link>
          ) : (
            <div key={id}>{content}</div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
