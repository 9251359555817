import React, { useState, useEffect } from 'react';
import { fetchVideos, Video } from '../assets/videoData';
import {
  Eye,
  ThumbsUp,
  ThumbsDown,
  MessageCircle,
  Calendar,
  Coins,
} from 'lucide-react';

const VideoList: React.FC = () => {
  const [videoData, setVideoData] = useState<Video[]>([]);

  useEffect(() => {
    fetchVideos().then(setVideoData);
  }, []);

  return (
    <div className="overflow-x-auto">
      <table className="w-full bg-soft-cloud dark:bg-darker-gray text-midnight-coal dark:text-soft-cloud rounded-lg">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-sm font-medium text-cadet-gray uppercase tracking-wider rounded-tl-lg">
              Thumbnail
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-cadet-gray uppercase tracking-wider">
              Video title
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-cadet-gray uppercase tracking-wider">
              <Calendar className="w-4 h-4 inline-block mr-2" />
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-cadet-gray uppercase tracking-wider">
              <Eye className="w-4 h-4 inline-block mr-2" />
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-cadet-gray uppercase tracking-wider">
              <ThumbsUp className="w-4 h-4 inline-block mr-2" />
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-cadet-gray uppercase tracking-wider">
              <ThumbsDown className="w-4 h-4 inline-block mr-2" />
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-cadet-gray uppercase tracking-wider">
              <MessageCircle className="w-4 h-4 inline-block mr-2" />
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-cadet-gray uppercase tracking-wider rounded-tr-lg">
              <Coins className="w-4 h-4 inline-block mr-2" />
            </th>
          </tr>
        </thead>
        <tbody>
          {videoData.map((video, index) => (
            <tr
              key={index}
              className={`${
                index % 2 === 0
                  ? 'bg-anti-flash-white dark:bg-dark-gray'
                  : 'bg-soft-cloud dark:bg-darker-gray'
              } ${
                index === 0
                  ? 'rounded-tl-lg rounded-tr-lg'
                  : index === videoData.length - 1
                    ? 'rounded-bl-lg rounded-br-lg'
                    : ''
              }`}
            >
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="aspect-w-16 aspect-h-9">
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    className="object-cover rounded"
                  />
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{video.title}</td>
              <td className="px-6 py-4 whitespace-nowrap">{video.uploadDate}</td>
              <td className="px-6 py-4 whitespace-nowrap">{video.views}</td>
              <td className="px-6 py-4 whitespace-nowrap">{video.likes}</td>
              <td className="px-6 py-4 whitespace-nowrap">{video.dislikes}</td>
              <td className="px-6 py-4 whitespace-nowrap">{video.comments}</td>
              <td className="px-6 py-4 whitespace-nowrap">{video.revenue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VideoList;
