import React, { useState, useEffect } from 'react';
import { fetchVideos, Video } from '../assets/videoData';
import { Eye } from 'lucide-react';

const VideoGrid: React.FC = () => {
  const [videoData, setVideoData] = useState<Video[]>([]);

  useEffect(() => {
    fetchVideos().then(setVideoData);
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {videoData.map((video, index) => (
        <div
          key={index}
          className="bg-soft-cloud dark:bg-darker-gray rounded-lg p-4 shadow-lg"
        >
          <div className="aspect-w-16 aspect-h-9 mb-4">
            <img
              src={video.thumbnail}
              alt={video.title}
              className="object-cover rounded"
            />
          </div>
          <div className="text-cadet-gray text-sm font-medium mb-2">
            CATEGORIES
          </div>
          <h2 className="text-lg font-semibold text-midnight-coal dark:text-soft-cloud mb-2">
            {video.title}
          </h2>
          <p className="text-sm text-midnight-coal dark:text-soft-cloud mb-4">
            {video.description}
          </p>
          <div className="text-midnight-coal dark:text-soft-cloud font-bold mb-2">
            {video.channelGroup}
          </div>
          <div className="flex items-center text-cadet-gray text-sm">
            <span className="flex items-center mr-4">
              <Eye className="w-4 h-4 mr-1" />
              {video.views}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoGrid;
