import React, { useEffect, useState } from 'react';
import FoldOutHeader from './FoldOutHeader';
import { UploadBox, ThumbnailUploadBox } from './UploadBox';
import VideoFormContent from './VideoFormContent';
import FoldOutFooter from './FoldOutFooter';
import { Channel } from '../types/channels';
import { UploadData } from '../types/uploadData';

const backendApiUrl = process.env.REACT_APP_API_URL;

interface FoldOutMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

const FoldOutMenu: React.FC<FoldOutMenuProps> = ({ isOpen, toggleMenu }) => {
  const [channels, setChannels] = useState<Channel[]>([]);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await fetch(`${backendApiUrl}/channel`, {
          method: 'GET',
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error('Failed to fetch channels');
        }
        const text = await response.text();
        let data;
        try {
          data = JSON.parse(text);
        } catch (parseError) {
          console.error('Error parsing JSON:', parseError);
          console.error('Raw response:', text);
          throw new Error('Invalid JSON response');
        }
        setChannels(data.channels.map((channel: Channel) => ({
          ...channel,
          isActive: false
        })));
      } catch (error) {
        console.error('Error fetching channels:', error);
      }
    };

    fetchChannels();
  }, []);

  // Form data state
  const [description, setDescription] = React.useState<string>('');
  const [title, setTitle] = React.useState<string>('');
  const [video, setVideo] = React.useState<File | null>(null);
  const [thumbnail, setThumnail] = React.useState<File | null>(null);
  const [upload, setUpload] = React.useState<UploadData>();


  useEffect(() => {
    const upload: UploadData = {
      userId: '',
      title,
      description,
      thumbnail: thumbnail ? URL.createObjectURL(thumbnail) : '',
      channels,
      status: '',
      error: '',
      mediaFIle: video
    };

    setUpload(upload);
  }, [title, description, thumbnail, video, channels]);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleMenu}
        ></div>
      )}
      <div
        className={`fixed top-0 right-0 transform transition-transform duration-300 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }
          w-full
          sm:w-4/5
          lg:w-3/5
          xl:w-2/5
          2xl:w-1/2
          min-w-[50%]
          max-h-[100vh]
          bg-anti-flash-white dark:bg-dark-gray 
          rounded-l-lg sm:rounded-none z-50 flex flex-col
          sm:max-h-[100vh] sm:max-w-[100vw]
        `}
      >
        <FoldOutHeader toggleMenu={toggleMenu} />

        <div className="flex-1 px-4 overflow-y-auto">
          <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0 mb-4">
            <UploadBox setUploadVideo={setVideo} className="flex-grow" />
            <ThumbnailUploadBox
              setThumbnail={setThumnail}
              className="flex-grow-0"
            />
          </div>
          <VideoFormContent
            setTitle={setTitle}
            setDescription={setDescription}
            title={title}
            description={description}
            channels={channels}
            setChannels={setChannels}
          />
          <FoldOutFooter toggleMenu={toggleMenu} upload={upload!} />
        </div>
      </div>
    </>
  );
};

export default FoldOutMenu;
